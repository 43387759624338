// Libraries
import React, { useState, useEffect, useContext } from "react";
import { Link, Route, useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import { useModal } from "../_common/Modal/useModal";
import useWindowSize from "../_common/hooks/useWindowSize";
import { useHideOrderModule } from "../App/useHideOrderModule";

// API helper functions
import { getAPIAccountInfo } from "./apiHelpers/getAPIAccountInfo";
import { getAPIRegistrationFields } from "./apiHelpers/getAPIRegistrationFields";
import { getAPICardList } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPICardList";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import AppLabelsContext from "../App/AppLabelsContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppSettingsContext from "../App/AppSettingsContext";
import BrandingContext from "../App/BrandingContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { PageComponentTemplate } from "../_common/components/PageComponentTemplate";
import { ChangeAccountDetailsFormDesktop } from "./ChangeAccountDetailsFormDesktop";
import { ChangePasswordFormDesktop } from "./ChangePasswordFormDesktop";
import { AppHeader } from "../App/AppHeader/AppHeader";
import { ChangePasswordForm } from "./ChangePasswordForm";
import { ChangeAccountDetailsForm } from "./ChangeAccountDetailsForm";
import { LoadingSpinner } from "../_common/LoadingSpinner";
import { AppFooter } from "../App/AppFooter/AppFooter";
import { Modal } from "../_common/Modal/Modal";
import { LogoutModalDialog } from "../_common/components/LogoutModalDialog";

// Assets
import { ReactComponent as IconArrowRight } from "../_common/icons/IconArrowRight.svg";

// CSS
import "./Account.css";

export const Account = () => {
  const history = useHistory();
  const { hideOrderModule } = useHideOrderModule();
  const { isModal, setIsModal, toggleModal, closeAnimation } = useModal();
  const deviceWidth = useWindowSize().width;

  const merchantConfigContext = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);
  const userRoleContext = useContext(UserRoleContext);
  const appLabels = useContext(AppLabelsContext);
  const appSettings = useContext(AppSettingsContext);
  const branding = useContext(BrandingContext);
  const appLanguage = useContext(AppLanguageContext);

  const [accountInfo, setAccountInfo] = useState(null);
  const [isStoredCreditCard, setIsStoredCreditCard] = useState("idle");
  const [reloadData, setReloadData] = useState(true);
  const [registrationFields, setRegistrationFields] = useState(null);
  const [numberOfPaymentMethods, setNumberOfPaymentMethods] = useState(null);
  const [modalSettings, setModalSettings] = useState(null);
  const [isLogoutAttempt, setIsLogoutAttempt] = useState(false);

  const skin = merchantConfigContext.skin;
  const merchantVexilorConfig = merchantConfigContext.vexilor.I2;
  const paymentMethods = merchantVexilorConfig.payment_methods;
  const isGiftCardEnabled = cws5ModuleContext.isGiftCardEnabled;
  const isOnlineOrderingEnabled = cws5ModuleContext.isOnlineOrderingEnabled;
  const isStadiumSchema = merchantConfigContext.merchant.I55 === "stadium";
  const loginToken = userRoleContext.loginToken;
  const isOrderStatusEnabled = merchantVexilorConfig["order_status_access"] === "t";

  useEffect(() => {
    if (loginToken === null) {
      history.push("/login-register");
    }
  }, [loginToken]);

  /** Only call API 950 here if __customerInfo is not stored in localforage */
  useEffect(() => {
    if (reloadData) {
      setReloadData(false);
      localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
        if (customerInfo) {
          setAccountInfo(customerInfo);
          setIsStoredCreditCard(customerInfo.storedCreditCard ? true : false);
        } else {
          getAPIAccountInfo(skin, loginToken, appLanguage).then(async (apiAccountInfo) => {
            if (apiAccountInfo) {
              if (apiAccountInfo.status === "expiredLoginToken") {
                userRoleContext.handleLoginTokenExpiration();
              } else if (!apiAccountInfo.error) {
                const accountInfo = await userRoleContext.updateAccountInfoInStorage({
                  result: apiAccountInfo,
                });
                setAccountInfo(accountInfo);
                setIsStoredCreditCard(accountInfo.storedCreditCard ? true : false);
              }
            }
          });
        }
      });
    }
  }, [reloadData]);

  /** Trigger the 1024 call only for desktop when the user is trying to see profile information */
  useEffect(() => {
    if (accountInfo && deviceWidth >= 600 && !window.location.href.includes("change-password")) {
      getAPIRegistrationFields(skin, accountInfo, appLanguage).then((apiRegistrationFields) => {
        if (apiRegistrationFields && !apiRegistrationFields.error) {
          setRegistrationFields(apiRegistrationFields);
        }
      });
    }
  }, [accountInfo, window.location.href]);

  useEffect(() => {
    const countMethodsOfPayment = async () => {
      let counter = 0;

      if (!!paymentMethods["GIFTCARD"]) {
        getAPICardList(
          skin,
          loginToken,
          appSettings["currency-symbol"],
          appSettings["currency-symbols-side"],
          appLanguage,
          branding
        ).then((apiData) => {
          if (!apiData.error) {
            if (apiData.status === "expiredLoginToken") {
              userRoleContext.handleLoginTokenExpiration();
            } else {
              counter += apiData.length;
              if (isStoredCreditCard) {
                counter += 1;
              }
              setNumberOfPaymentMethods(counter.toString());
            }
          }
        });
      }
    };

    if (loginToken && isStoredCreditCard !== "idle") {
      countMethodsOfPayment();
    }
  }, [isStoredCreditCard, loginToken]);

  const openAccountDetailsForm = () => {
    getAPIRegistrationFields(skin, accountInfo, appLanguage).then((apiRegistrationFields) => {
      if (apiRegistrationFields && !apiRegistrationFields.error) {
        setRegistrationFields(apiRegistrationFields);

        setModalSettings({
          content: (
            <ChangeAccountDetailsForm
              skin={skin}
              fields={apiRegistrationFields}
              setRegistrationFields={setRegistrationFields}
              setIsModal={setIsModal}
              setReloadData={setReloadData}
            />
          ),
          type: "fullscreen",
          isTransparent: true,
          description: appLabels["account"]["update-account-details"],
          wrapperClass: "modal-wrapper--account-update",
          isCloseButton: true,
        });
        toggleModal();
      }
    });
  };

  const openPasswordForm = () => {
    setModalSettings({
      content: <ChangePasswordForm skin={skin} login={accountInfo.email} setIsModal={setIsModal} />,
      type: "fullscreen",
      description: "Change Password",
      wrapperClass: "modal-wrapper--account-update",
      isCloseButton: true,
    });
    toggleModal();
  };

  return (
    <>
      {!!registrationFields && (
        <Route
          path="/account/profile/personal-info"
          render={() => (
            <PageComponentTemplate
              mainClassOverride="account-main-content account-info__main-container"
              component={
                <ChangeAccountDetailsFormDesktop
                  fields={registrationFields}
                  setRegistrationFields={setRegistrationFields}
                  setIsModal={setIsModal}
                />
              }
              isFooter={false}
            />
          )}
        />
      )}

      {!!accountInfo && (
        <Route
          path="/account/profile/change-password"
          render={() => (
            <PageComponentTemplate
              mainClassOverride="account-main-content account-info__change-password-main-container"
              component={<ChangePasswordFormDesktop skin={skin} login={accountInfo.email} />}
              isFooter={false}
            />
          )}
        />
      )}
      <Route
        exact
        path="/account/profile"
        render={() => (
          <>
            {deviceWidth < 660 && (
              <AppHeader
                pageHeading={appLabels["general"]["account"]}
                isBackButton={true}
                isHomeLink={true}
              />
            )}
            <main id="main-content" className="main-content account-main-content">
              {accountInfo ? (
                <>
                  <section className="account-section account-section--email-and-logout">
                    <div className="account-section__email">
                      {accountInfo.login ? accountInfo.login : accountInfo.email}
                    </div>
                  </section>
                  <section className="account-section">
                    <h2 className="account-section__heading">{appLabels["account"]["profile"]}</h2>
                    <ul className="account-section__editable-items-list">
                      <li className="account-section__editable-item">
                        <button
                          onClick={openAccountDetailsForm}
                          className="account-section__edit-item-button"
                          type="button"
                        >
                          <span>{appLabels["account"]["personal-info"]}</span>
                          <IconArrowRight aria-hidden="true" />
                        </button>
                      </li>

                      <li className="account-section__editable-item">
                        <button
                          onClick={openPasswordForm}
                          className="account-section__edit-item-button"
                          type="button"
                        >
                          <span>{appLabels["account"]["change-password"]}</span>
                          <IconArrowRight aria-hidden="true" />
                        </button>
                      </li>

                      <li className="account-section__editable-item">
                        <Link
                          to="/account/account-deletion"
                          className="account-section__edit-item-link"
                        >
                          {appLabels["account"]["delete-account"]}
                          <IconArrowRight aria-hidden="true" />
                        </Link>
                      </li>

                      {merchantVexilorConfig.active_order_types.includes("delivery") &&
                        !isStadiumSchema &&
                        isOnlineOrderingEnabled && (
                          <li className="account-section__editable-item">
                            <Link
                              to="/account/delivery-address"
                              className="account-section__edit-item-link"
                            >
                              {appLabels["order"]["delivery-addresses"]}
                              <IconArrowRight aria-hidden="true" />
                            </Link>
                          </li>
                        )}

                      {(isGiftCardEnabled || isOnlineOrderingEnabled) && (
                        <li className="account-section__editable-item">
                          <Link
                            to="/account/payment-methods"
                            className="account-section__edit-item-link"
                          >
                            <div>
                              {appLabels["account"]["payment-methods"]}
                              <span className="account-section__payment-methods-counter">
                                {numberOfPaymentMethods && "(" + numberOfPaymentMethods + ")"}
                              </span>
                            </div>
                            <IconArrowRight aria-hidden="true" />
                          </Link>
                        </li>
                      )}
                    </ul>
                  </section>
                  {((!hideOrderModule && isOnlineOrderingEnabled) || isGiftCardEnabled) && (
                    <section className="account-section">
                      <h2 className="account-section__heading">
                        {appLabels["account"]["my-orders"]}
                      </h2>
                      <ul className="account-section__editable-items-list">
                        {isOnlineOrderingEnabled && (
                          <>
                            {isOrderStatusEnabled && (
                              <li className="account-section__editable-item">
                                <Link
                                  to="/online-ordering/recent-orders/active-orders"
                                  className="account-section__edit-item-link"
                                >
                                  <span>{appLabels["order"]["active-orders"]}</span>
                                  <IconArrowRight aria-hidden="true" />
                                </Link>
                              </li>
                            )}
                            <li className="account-section__editable-item">
                              <Link
                                to="/online-ordering/recent-orders/past-orders"
                                className="account-section__edit-item-link"
                              >
                                <span>{appLabels["order"]["past-orders"]}</span>
                                <IconArrowRight aria-hidden="true" />
                              </Link>
                            </li>
                          </>
                        )}
                        {isGiftCardEnabled && (
                          <li className="account-section__editable-item giftCardRecentOrdersNavListItem">
                            <Link
                              to="/gift-card/recent"
                              className="account-section__edit-item-link"
                            >
                              <span>{appLabels["gift-card"]["gift-card-nav-label"]}</span>
                              <IconArrowRight aria-hidden="true" />
                            </Link>
                          </li>
                        )}
                      </ul>
                    </section>
                  )}
                </>
              ) : (
                <LoadingSpinner />
              )}
            </main>
            {deviceWidth < 660 && <AppFooter />}
            {modalSettings != null && (
              <Modal
                isModal={isModal}
                isTransparent={modalSettings.isTransparent}
                toggleModal={toggleModal}
                content={modalSettings.content}
                type={modalSettings.type}
                description={modalSettings.description}
                closeAnimation={closeAnimation}
                isCloseButton={modalSettings.isCloseButton}
                wrapperClass={modalSettings.wrapperClass}
              />
            )}
            {isLogoutAttempt && (
              <LogoutModalDialog resetRemoveDialog={() => setIsLogoutAttempt(false)} />
            )}
          </>
        )}
      />
    </>
  );
};
