import he from "he";
import { callPaymentAPI } from "../../../../../_common/Api";

export const placeUnifiedAPIOrder = async (
  skin,
  activeStore,
  orderType,
  customerInfo,
  rewards,
  promoCode,
  giftCardNumberList,
  activeType,
  formValues,
  storedCreditCardId,
  isRegularForm,
  orderTypeInstruction = "",
  tipAmount,
  language,
  generalLabels,
  hostTransactionId
) => {
  let order_address = activeStore.address;
  let order_postal = activeStore.postal;
  let order_city = activeStore.city;

  const ccType = activeType.toUpperCase();

  let ccNumber = "";
  let ccName = "";
  let ccExpiryMonth = "";
  let ccExpiryYear = "";
  let cvv = "";
  let ccSave = "";

  if (!storedCreditCardId || isRegularForm) {
    ccNumber = formValues["credit-card-number"].replace(/\s/g, "");
    ccName = formValues["credit-card-name"];

    const ccExpiry = formValues["credit-card-expiry"];
    ccExpiryMonth = ccExpiry.split("/")[0];
    ccExpiryYear = "20" + ccExpiry.split("/")[1];

    cvv = formValues["credit-card-cvv"];
    ccSave = formValues["save-credit-card"];

    storedCreditCardId = "";
  }

  const address1 = formValues["first-address"];
  const address2 = formValues["second-address"];
  const city = formValues["city"];
  const province = formValues["province"];
  const country = formValues["country"];
  const postal = formValues["postal"];

  const placeOrderData = await callPaymentAPI(skin, "dc_vxl_pay_bill", {
    params: [
      skin,
      "consumer",
      language,
      activeStore.storeId, //outlet id
      hostTransactionId, //Host transaction ID
      "", //workstation ID (optional)
      {
        customer_id: customerInfo.id || "",
        order_email: customerInfo.email || "",
        order_fname: customerInfo.firstName || "",
        order_lname: customerInfo.lastName || "",
        order_phone: customerInfo.phone || "",
        order_mobile: customerInfo.mobile || "",
        order_address,
        order_postal,
        order_city,
        order_prov: activeStore.province,
        order_country: activeStore.country,
        discount_for_the_whole_order: promoCode,
        redeem_rewards: rewards,
      }, // CustomerInfo
      giftCardNumberList, // gc_number_list
      ccSave ? "t" : "f", // save_credit_card
      "", // tm_session_id
      "", // scene_cardnum
      "CREDITCARD", // payment_type
      storedCreditCardId || "", // cc_id
      ccType || "", // cc_type
      ccNumber || "", // cc_number
      ccName || "", // cc_name
      cvv || "", // cc_security
      "", // cc_issue
      ccExpiryMonth || "", // cc_expiry_month
      ccExpiryYear || "", // cc_expiry_year
      "", // cc_start_month
      "", // cc_start_year
      address1 || "", // cc_address1
      address2 || "", // cc_address2
      city || "", // cc_city
      province || "", // cc_province
      country || "", // cc_country
      postal || "", // cc_postal
      customerInfo.email, // cc_email
      customerInfo.phone || "", // cc_phone
      "", // pa_res
      "", // return_url
      "", // cancel_url
      "", // success_url
      "", // error_url
      "", // fail_url
      "", // unique_pay_id
      "", // wechat_oauth_id
      window.location.origin + window.location.pathname + "#/online-ordering/payment-confirmation", // url_3ds_success
      window.location.origin +
        window.location.pathname +
        "#/online-ordering/bill-payment?3dsecure=rejected", // url_3ds_failure
      orderType === "pickup" || orderType === "dinein" ? orderTypeInstruction : "None", //Special Instruction (pickup / dinein)
      tipAmount, //tip
      customerInfo.email || "", //Receipt Email
    ],
    id: "dc_vxl_pay_bill",
  });

  if (placeOrderData.result.I0) {
    /* successfully placed an order */
    return {
      isPlaced: true,
      orderId: placeOrderData.result.I0,
      pointsIssued: placeOrderData.result.I10,
      mpiForm: !!placeOrderData.result.I9 ? placeOrderData.result.I9 : null,
    };
  } else {
    /* failed to place an order */
    return {
      isPlaced: false,
      error: `${generalLabels["error-code"]}: ${placeOrderData.error.code}. ${he.decode(
        placeOrderData.result.message
      )}`,
    };
  }
};
