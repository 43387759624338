// Libraries
import React, { useContext, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// API helper functions
import { addAPICreditCard } from "./addAPICreditCard.js";
import { getAPIBillingAddress } from "./getAPIBillingAddress";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLabelsContext from "../App/AppLabelsContext";

// UI components
import { AppHeader } from "../App/AppHeader/AppHeader";
import { PaymentMethodCreditCardIcons } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodCreditCard/PaymentMethodCreditCardIcons";
import { Form } from "../_common/Form/Form";
import { FormFieldset } from "../_common/Form/FormFieldset";
import { FormInput } from "../_common/Form/FormInput";
import { FormSelect } from "../_common/Form/FormSelect";
import { ReactComponent as IconLock } from "../_common/icons/IconLock.svg";
import { LoadingSpinner } from "../_common/LoadingSpinner";

// CSS
import "./AddCreditCard.css";
import { AppDesktopHeader } from "../App/AppHeader/AppDesktopHeader";

export const AddCreditCard = ({
  goBackToPayInStore,
  setStoredMaskedCCInfo,
  setShowCreditCardForm,
}) => {
  const merchantConfigContext = useContext(MerchantConfigContext);
  const skin = merchantConfigContext.skin;
  const vexilorConfig = merchantConfigContext.vexilor;

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  const [activeType, setActiveType] = useState("");
  const updateActiveType = (type) => setActiveType(type.toLowerCase());

  const [isAPISubmitValid, setIsAPISubmitValid] = useState(null);
  const [apiError, setAPIError] = useState("");
  const [isAPISubmitting, setAPISubmitting] = useState(false);
  const history = useHistory();

  const [customerID, setCustomerID] = useState(null);
  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    if (loginToken === null) {
      history.push("/login-register");
    }
  }, [loginToken]);

  function maskString(str) {
    // Check if the input string length is less than or equal to 4
    if (str.length <= 4) {
      return str;
    }

    // Create a string of asterisks of length equal to the input string length minus 4
    let maskedPart = "*".repeat(str.length - 4);

    // Concatenate the string of asterisks with the last 4 characters of the input string
    return maskedPart + str.substring(str.length - 4);
  }

  const addCreditCardToAccount = (formData) => {
    setAPISubmitting(true);

    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    // Payment API's add_credit_card, send customerID to update the account information
    addAPICreditCard(skin, activeType, formValues, customerID, customerInfo, appLanguage).then(
      async (apiCreditCardId) => {
        setAPISubmitting(false);

        if (apiCreditCardId) {
          if (apiCreditCardId.status === "expiredLoginToken") {
            userRoleContext.handleLoginTokenExpiration();
          } else if (apiCreditCardId.isAdded) {
            if (apiCreditCardId.mpiForm) {
              // Redirect to MPI form
              let dom = new DOMParser().parseFromString(apiCreditCardId.mpiForm, "text/html");
              let newElement = dom.body.firstElementChild;
              document.body.appendChild(newElement);
              let formId = newElement.id;
              //submitting the form will perform the redirection
              document.getElementById(formId).submit();
            } else {
              const customerInfo = await localforage.getItem(skin + "__customerInfo");
              let tempCustomerInfo = {};
              if (customerInfo) {
                tempCustomerInfo = { ...customerInfo };
                tempCustomerInfo.storedCreditCard = {
                  id: apiCreditCardId.creditCardId,
                  expiry: formValues["credit-card-expiry"],
                  maskedNumber: maskString(formValues["credit-card-number"]),
                };

                await localforage.setItem(skin + "__customerInfo", tempCustomerInfo);
              }

              setIsAPISubmitValid(true);
              if (goBackToPayInStore) {
                setTimeout(() => {
                  if (setStoredMaskedCCInfo) {
                    setStoredMaskedCCInfo(tempCustomerInfo.storedCreditCard);
                  }

                  if (setShowCreditCardForm) {
                    setShowCreditCardForm(false);
                  }
                  history.push("/pay-in-store/add-money");
                }, 1000);
              } else if (window.location.href.includes("#paymentMethods")) {
                setTimeout(() => {
                  history.push("/account/payment-methods");
                }, 1000);
              } else {
                history.goBack();
              }
            }
          } else {
            setAPIError(apiCreditCardId.error);
            setIsAPISubmitValid(false);
          }
        }
      }
    );
  };

  const [enabledTypes, setEnabledTypes] = useState(null);
  useEffect(() => {
    const allPaymentMethods = vexilorConfig.I2.payment_methods;
    let creditCardTypes = allPaymentMethods.CREDIT.map((paymentMethod) =>
      paymentMethod[0].toLowerCase()
    );

    /** Special case to detect Private Label CC in the payment methods */
    if (allPaymentMethods["OTHERS"]) {
      allPaymentMethods["OTHERS"].forEach((paymentMethod) => {
        let method = paymentMethod[0].toLowerCase();
        if (method === "private") {
          creditCardTypes.push("private");
        }
      });
    }
    setEnabledTypes(creditCardTypes);
  }, []);

  useEffect(() => {
    localforage.getItem(skin + "__customerID").then((customerId) => {
      if (customerId) setCustomerID(customerId);
    });

    localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
      if (customerInfo) {
        let tempCustomerInfo = { ...customerInfo };

        if (loginToken) {
          tempCustomerInfo.loginToken = loginToken;
          setCustomerInfo(tempCustomerInfo);
        }
      }
    });
  }, []);

  const appLanguage = useContext(AppLanguageContext);

  const [billingAddress, setBillingAddress] = useState(null);
  useEffect(() => {
    getAPIBillingAddress(skin, loginToken, appLanguage).then((apiBillingAddress) => {
      if (apiBillingAddress) {
        if (apiBillingAddress.status === "expiredLoginToken") {
          userRoleContext.handleLoginTokenExpiration();
        } else if (apiBillingAddress.error) {
          setBillingAddress(false);
        } else {
          setBillingAddress(apiBillingAddress);
        }
      }
    });
  }, []);

  const deviceWidth = useWindowSize().width;

  const appLabels = useContext(AppLabelsContext);

  const [isSmall, setIsSmall] = useState(true);
  useEffect(() => {
    if (deviceWidth >= 660) {
      setIsSmall(true);
    } else {
      setIsSmall(false);
    }
  }, [deviceWidth]);

  const [isMaskedPhoneNumber, setIsMaskedPhoneNumber] = useState(true);
  const onCountryDropDownChange = (target) => {
    const selectedCountryCode = target.value;
    if (selectedCountryCode === "CA" || selectedCountryCode === "US") {
      setIsMaskedPhoneNumber(true);
    } else {
      setIsMaskedPhoneNumber(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (document.getElementById("select--country")) {
        onCountryDropDownChange(document.getElementById("select--country"));
      }
    }, 500);
  }, []);

  return (
    <>
      {deviceWidth < 660 ? (
        <AppHeader
          pageHeading={appLabels["account"]["add-credit-card"]}
          isBackButton={true}
          isHomeLink={true}
        />
      ) : (
        <AppDesktopHeader />
      )}
      <main
        id="main-content"
        className={`main-content main-content--with-FAB add-credit-card-main-content${
          deviceWidth >= 660 ? " desktop-container" : ""
        }`}
      >
        {enabledTypes && (
          <PaymentMethodCreditCardIcons activeType={activeType} creditCardTypes={enabledTypes} />
        )}
        {billingAddress != null ? (
          <Form
            submitForm={addCreditCardToAccount}
            updateActiveCreditCardType={updateActiveType}
            resetAPIError={() => setAPIError("")}
            submitButtonText={appLabels["account"]["save-card"]}
            submitButtonSuccessText={appLabels["account"]["saving-card"]}
            id="form--add-credit-card"
            className="form--credit-card"
            isAPISubmitValid={isAPISubmitValid}
            isAPISubmitting={isAPISubmitting}
            submitAPIError={apiError}
          >
            <FormFieldset id="fieldset--card-details" legend={appLabels["form"]["card-details"]}>
              <FormInput
                isMaskedInput={true}
                type="tel"
                label={appLabels["form"]["credit-card-number"]}
                id="input--credit-card-number"
                name="credit-card-number"
                isRequired={true}
                isSmall={isSmall}
              />
              <FormInput
                type="text"
                label={appLabels["form"]["name-on-card"]}
                placeholder="Jonathan Smith"
                id="input--credit-card-name"
                name="credit-card-name"
                isRequired={true}
                defaultValue={billingAddress.name}
                isSmall={isSmall}
              />
              <FormInput
                isMaskedInput={true}
                type="tel"
                label={appLabels["form"]["expiry-date"]}
                placeholder="mm/yy"
                id="input--credit-card-expiry"
                name="credit-card-expiry"
                isRequired={true}
                isSmall={true}
              />
              <FormInput
                isMaskedInput={true}
                type="tel"
                label={appLabels["form"]["cvv"]}
                id="input--credit-card-cvv"
                name="credit-card-cvv"
                isRequired={true}
                isSmall={true}
              />
            </FormFieldset>
            <FormFieldset
              id="fieldset--billing-address"
              legend={appLabels["form"]["billing-address"]}
            >
              <FormInput
                type="text"
                label={appLabels["form"]["address-1"]}
                id="input--first-address"
                name="first-address"
                isRequired={true}
                defaultValue={billingAddress.address1}
                isSmall={isSmall}
              />
              <FormInput
                type="text"
                label={appLabels["form"]["address-2"]}
                id="input--second-address"
                name="second-address"
                defaultValue={billingAddress.address2}
                isSmall={isSmall}
              />
              <FormSelect
                id="select--country"
                name="country"
                label={appLabels["form"]["country"]}
                isRequired={true}
                defaultValue={billingAddress.country}
                isSmall={isSmall}
                callBack={onCountryDropDownChange}
              />
              <FormSelect
                id="select--province"
                name="province"
                label={appLabels["form"]["province"]}
                isRequired={true}
                defaultValue={billingAddress.province}
                isSmall={isSmall}
              />
              <FormInput
                type="text"
                label={appLabels["form"]["city"]}
                id="input--city"
                name="city"
                isRequired={true}
                defaultValue={billingAddress.city}
                isSmall={isSmall}
              />
              <FormInput
                isMaskedInput={true}
                type="text"
                label={appLabels["form"]["postal"]}
                id="input--postal"
                name="postal"
                isRequired={true}
                defaultValue={billingAddress.postal}
                isSmall={isSmall}
              />
              <FormInput
                type="text"
                label={appLabels["form"]["phone"]}
                id="input--phone"
                name="phone"
                isRequired={true}
                defaultValue={billingAddress.phone}
                isSmall={isSmall}
                isMaskedInput={isMaskedPhoneNumber}
              />
            </FormFieldset>
            <div className="add-credit-card-form__security-message-wrapper">
              <IconLock />
              <p className="add-credit-card-form__security-message">
                {appLabels["form"]["form-security-message"]}
              </p>
            </div>
          </Form>
        ) : (
          <LoadingSpinner />
        )}
      </main>
    </>
  );
};
