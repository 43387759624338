import { callMarquiPaymentAPI } from "../_common/Api";

export const giftCardManualReload = async (
  skin,
  formValues,
  cardNumber,
  reloadAmount,
  loginToken,
  creditCardId = "",
  language,
  customerInfo
) => {
  let type,
    number,
    name,
    expiryMonth,
    expiryYear,
    cvv,
    address1,
    address2,
    city,
    province,
    country,
    postal,
    email,
    phone = "";
  if (formValues) {
    cvv = formValues["cc-cvv"];
  }

  email = customerInfo.email || "";

  const manualReloadData = await callMarquiPaymentAPI(skin, "manual_reload", {
    params: [
      skin,
      "consumer",
      language,
      cardNumber, //cardnum
      reloadAmount, //amount
      "", //tm_session_id
      "", //scene_cardnum
      "CREDITCARD", // payment_type
      creditCardId, //cc_id
      type || "", //cc_type
      number || "", // cc_number
      name || "", // cc_name
      cvv || "", // cc_security
      "", // cc_issue
      expiryMonth || "", //cc_expiry_month
      expiryYear || "", //cc_expiry_year
      "", // cc_start_month
      "", // cc_start_year
      address1 || "", //cc_address1
      address2 || "", //cc_address2
      city || "", //cc_city
      province || "", //cc_province
      country || "", //cc_country
      postal || "", //cc_postal
      email || "", //cc_email
      phone || "", //cc_phone
      "", // pa_res
      "", // return_url
      "", // cancel_url
      "", // pay_dollar_success_url
      "", // pay_dollar_error_url
      "", // pay_dollar_fail_url
      "", // unique_pay_id
      "", // wechat_oauth_id
      "", // promo_offer_code
      loginToken, //customer_login
      "", //customer_password
      window.location.origin +
        window.location.pathname +
        (window.location.hash.includes("paymentMethods")
          ? "#/account/payment-methods"
          : "#/pay-in-store/"), // url_3ds_success
      window.location.origin +
        window.location.pathname +
        (window.location.hash.includes("paymentMethods")
          ? "#/account/payment-methods?3dsecure=rejected"
          : "#/pay-in-store/add-money?3dsecure=rejected"), // url_3ds_failure
      email, //confirm_email
      "f", //save_cust_cc
      "t", //use_cust_cc
    ],
    id: "manual_reload",
  });

  if (manualReloadData.result.I0) {
    // Successfully added a credit card
    return {
      isReloaded: true,
      creditCardId: manualReloadData.result.I1,
      mpiForm: manualReloadData.result.I7,
    };
  } else if (
    manualReloadData.status === 249 ||
    manualReloadData.status === 150 ||
    manualReloadData.error.message.includes(
      "Stored credit card can only be used when a customer is logged in"
    ) ||
    manualReloadData.error.message.includes(
      "Customer ID can only be used when a customer is logged in"
    )
  ) {
    return { status: "expiredLoginToken" };
  } else {
    // Failed to add a credit card
    return { isReloaded: false, error: manualReloadData.result.message };
  }
};
