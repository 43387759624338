// Libraries
import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import localforage from "localforage";

// Custom hooks
import useWindowSize from "../_common/hooks/useWindowSize";

// Helper functions
import { toDollars } from "../_common/helpers";

// API helper functions
import { callAPI } from "../_common/Api";
import { getAPIFullCardNumber } from "../OnlineOrdering/Payment/PaymentMethod/PaymentMethodGiftCard/apiHelpers/getAPIFullCardNumber";
import { giftCardManualReload } from "../Account/giftCardManualReload";

// Contexts
import UserRoleContext from "../App/UserRoleContext";
import AppSettingsContext from "../App/AppSettingsContext";
import AppLanguageContext from "../App/AppLanguageContext";
import MerchantConfigContext from "../App/MerchantConfigContext";
import AppLabelsContext from "../App/AppLabelsContext";
import CWS5ModuleContext from "../App/CWS5ModuleContext";

// UI components
import { AppHeader } from "../App/AppHeader/AppHeader";
import { AddCreditCard } from "../Account/AddCreditCard";
import { DialogModal } from "../_common/DialogModal/DialogModal";
import { AppDesktopHeader } from "../App/AppHeader/AppDesktopHeader";
import { FormInput } from "../_common/Form/FormInput";
import { Form } from "../_common/Form/Form";

// Assets
import { ReactComponent as IconArrowDown } from "../_common/icons/IconArrowDown.svg";
import { ReactComponent as IconCheck } from "../_common/icons/IconCheck.svg";
import { ReactComponent as IconPlusCircle } from "../_common/icons/IconPlusCircle.svg";

// CSS
import "./AddMoney.css";
import "../_common/Form/FormSelect.css";

export const AddMoney = (props) => {
  const {
    storedMaskedCCInfo,
    loyaltyCards,
    setActiveLoyaltyCard,
    activeLoyaltyCard,
    setReloadScreen,
    isGiftCardAutoReloadServiceEnabled,
    setStoredMaskedCCInfo,
  } = props;

  const history = useHistory();

  const merchantConfig = useContext(MerchantConfigContext);
  const cws5ModuleContext = useContext(CWS5ModuleContext);

  const isAutoReloadEnabled = cws5ModuleContext.isAutoReloadEnabled;
  const isManualReloadEnabled = cws5ModuleContext.isManualReloadEnabled;
  const skin = merchantConfig.skin;

  const [autoReload, setAutoReload] = useState(!isManualReloadEnabled);
  const [alreadyHasAutoReload, setAlreadyHasAutoReload] = useState(false);

  const [reloadAmount, setReloadAmount] = useState(10);
  const [reloadFrequecyType, setReloadFrequencyType] = useState("th");
  const [reloadThreshold, setReloadThreshold] = useState(5);

  const [paymentMethod, setPaymentMethod] = useState("cc");
  const [selectedCardFullNumber, setSelectedCardFullNumber] = useState(null);
  const [reloadErrorMessage, setReloadErrorMessage] = useState("");

  const [showCreditCardForm, setShowCreditCardForm] = useState(false);

  const [areOptionsValid, setAreOptionsValid] = useState(null);

  const [hasStoredCreditCard, setHasStoredCreditCard] = useState(false);
  const [autoReloadAmount, setAutoReloadAmount] = useState(10);

  const [customerInfo, setCustomerInfo] = useState(null);

  useEffect(() => {
    if (storedMaskedCCInfo) {
      setPaymentMethod(storedMaskedCCInfo.id);
      setHasStoredCreditCard(true);
    }
  }, [storedMaskedCCInfo]);

  const userRoleContext = useContext(UserRoleContext);
  const loginToken = userRoleContext.loginToken;

  useEffect(() => {
    if (loginToken) {
      localforage.getItem(skin + "__customerInfo").then((customerInfo) => {
        if (customerInfo) {
          setCustomerInfo(customerInfo);
          if (customerInfo.storedCreditCard && customerInfo.storedCreditCard.id) {
            setStoredMaskedCCInfo(customerInfo.storedCreditCard);
          } else {
            setStoredMaskedCCInfo(false);
          }
        }
      });
    }
  }, [history.location.hash === "#refresh-add-money"]);

  const [is3dSecureRejected, setIs3dSecureRejected] = useState(null);
  useEffect(() => {
    const url = window.location.href;
    const parameters = url.split("?");
    if (parameters.length > 1) {
      const paymentRejected = parameters[1].split("=")[1] === "rejected";
      setIs3dSecureRejected(paymentRejected);
    }
  }, []);

  useEffect(() => {
    if (activeLoyaltyCard && isGiftCardAutoReloadServiceEnabled) {
      //Check and see if the select card already has an auto reload set
      callAPI(merchantConfig.skin, "dc_1023", {
        params: [
          appLanguage,
          "1023",
          "mqid",
          "mqpass",
          loginToken,
          "",
          activeLoyaltyCard.iso,
          activeLoyaltyCard.serial,
        ],
        id: "1023",
      })
        .then((response1023) => {
          if (response1023.result.I1 === "0") {
            if (response1023.result.I5 === "") {
              //has no auto reload set
              setAlreadyHasAutoReload(false);
              setAutoReloadAmount(10);
              setReloadThreshold(5);
              setReloadFrequencyType("th");
            } else {
              setAlreadyHasAutoReload(true);
              setAutoReload(true);
              updateReloadThreshold(response1023.result.I8);
              udpdateAutoReloadAmount(response1023.result.I7);

              if (response1023.result.I6 !== "th") {
                updateReloadFrequencyType(response1023.result.I6);
              }
            }
          } else if (response1023.status === 249 || response1023.status === 150) {
            userRoleContext.handleLoginTokenExpiration();
          } else {
            console.log(response1023.error);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [activeLoyaltyCard, isGiftCardAutoReloadServiceEnabled]);

  const appLabels = useContext(AppLabelsContext);

  const paymentFrequencyOptions = [
    { value: "th", label: appLabels["form"]["select-one"] },
    { value: "wk", label: appLabels["form"]["weekly"] },
    { value: "mn", label: appLabels["form"]["monthly"] },
  ];

  const AutoReloadToggleOnChange = () => {
    if (autoReload) {
      setReloadThreshold(5);
      setReloadFrequencyType("th");
    }

    setAutoReload(!autoReload);
  };

  const updateReloadAmount = (amount) => {
    // to make sure empty "" value doesn't get converted to 0
    if (amount) setReloadAmount(Number(amount));
    else setReloadAmount(amount);
  };

  const udpdateAutoReloadAmount = (amount) => {
    setAutoReloadAmount(amount);
  };

  const updateReloadThreshold = (amount) => {
    setReloadThreshold(amount);
    setReloadFrequencyType("th");
  };

  const updateReloadFrequencyType = (type) => {
    setReloadFrequencyType(type);
    if (type === "th") {
      setReloadThreshold(5);
    } else {
      setReloadThreshold("");
    }
  };

  const updateSelectedGiftCard = (e) => {
    const select = e.target;
    const selectedSerial = select.value;

    loyaltyCards.forEach((giftCard) => {
      if (giftCard.serial === selectedSerial) {
        setActiveLoyaltyCard(giftCard);
      }
    });
  };

  const [showAutoReloadMessage, setShowAutoReloadMessage] = useState(null);
  const [autoReloadMessage, setAutoReloadMessage] = useState(null);
  const acknowledgeAutoReloadMessage = () => {
    setReloadScreen(true);
    setShowAutoReloadMessage(false);
    if (window.location.href.includes("paymentMethods")) {
      history.goBack();
    } else {
      history.push("/pay-in-store#refresh");
    }
  };

  const appSettings = useContext(AppSettingsContext);
  const appLanguage = useContext(AppLanguageContext);

  const handleReload = (formData) => {
    const formValues = {};
    formData.forEach((formField) => (formValues[formField.name] = formField.value));

    if (storedMaskedCCInfo.id && paymentMethod === storedMaskedCCInfo.id) {
      if (!autoReload) {
        if (selectedCardFullNumber === null) {
          getAPIFullCardNumber(
            merchantConfig.skin,
            loginToken,
            activeLoyaltyCard,
            appLanguage
          ).then((fullNumber) => {
            if (fullNumber) {
              if (fullNumber.status === "expiredLoginToken") {
                userRoleContext.handleLoginTokenExpiration();
              } else {
                setSelectedCardFullNumber(fullNumber);

                giftCardManualReload(
                  merchantConfig.skin,
                  formValues,
                  fullNumber,
                  reloadAmount,
                  loginToken,
                  storedMaskedCCInfo.id,
                  appLanguage,
                  customerInfo
                ).then((response) => {
                  if (response) {
                    if (response.isReloaded) {
                      setAreOptionsValid(true);

                      if (response.mpiForm) {
                        let dom = new DOMParser().parseFromString(response.mpiForm, "text/html");
                        let newElement = dom.body.firstElementChild;
                        document.body.appendChild(newElement);
                        let formId = newElement.id;
                        //submitting the form will perform the redirection
                        document.getElementById(formId).submit();
                      } else {
                        if (window.location.href.includes("paymentMethods")) {
                          history.goBack();
                        } else {
                          setReloadScreen(true);
                          history.push("/pay-in-store#refresh");
                        }
                      }
                    } else if (response.status === "expiredLoginToken") {
                      userRoleContext.handleLoginTokenExpiration();
                    } else {
                      setReloadErrorMessage(response.error);
                      setAreOptionsValid(false);
                    }
                  }
                });
              }
            }
          });
        }
      } else {
        callAPI(merchantConfig.skin, "dc_1021", {
          params: [
            appLanguage,
            "1021",
            "mqid",
            "mqpass",
            loginToken,
            "",
            activeLoyaltyCard.iso,
            activeLoyaltyCard.serial,
            "fr",
            reloadFrequecyType,
            autoReloadAmount,
            reloadThreshold,
            storedMaskedCCInfo.id,
          ],
          id: "1021",
        })
          .then((response1021) => {
            if (response1021.result.I1 === "0") {
              setAreOptionsValid(true);
              setShowAutoReloadMessage(true);

              const dialogMessage = `${
                appLabels["order"]["card-autoreload-success-message"].split("[card-number]")[0]
              }${activeLoyaltyCard.maskedNumber}${
                appLabels["order"]["card-autoreload-success-message"]
                  .split("[card-number]")[1]
                  .split("[amount]")[0]
              }${toDollars(
                appSettings["currency-symbol"],
                appSettings["currency-symbol-side"],
                autoReloadAmount,
                appLanguage
              )}${
                reloadFrequecyType === "th"
                  ? `, ${appLabels["order"]["card-autoreload-when-balance-reaches"]} ${toDollars(
                      appSettings["currency-symbol"],
                      appSettings["currency-symbol-side"],
                      reloadThreshold,
                      appLanguage
                    )}`
                  : ` ${
                      reloadFrequecyType === "wk"
                        ? appLabels["order"]["card-autoreload-weekly-frequency"]
                        : appLabels["order"]["card-autoreload-monthly-frequency"]
                    }`
              }.`;

              setAutoReloadMessage(dialogMessage);
            } else if (response1021.status === 249 || response1021.status === 150) {
              userRoleContext.handleLoginTokenExpiration();
            } else {
              setReloadErrorMessage(response1021.error);
              setAreOptionsValid(false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      setShowCreditCardForm(true);
    }

    setTimeout(() => setAreOptionsValid(null), 2000);
  };

  const deviceWidth = useWindowSize().width;

  const [displayAmount, setDisplayAmount] = useState("");
  useEffect(() => {
    if (reloadAmount) {
      setDisplayAmount(
        Number.isInteger(parseFloat(reloadAmount))
          ? reloadAmount.toLocaleString(appLanguage)
          : reloadAmount.toLocaleString(appLanguage, { minimumFractionDigits: 2 })
      );
    }
  }, [reloadAmount]);

  const currencySymbol = appSettings["currency-symbol"] || "$";
  const isLeftCurrencySymbol = (appSettings["currency-symbol-side"] || "left") === "left";

  return (
    <>
      <div className="addMoney__container">
        {deviceWidth < 660 ? (
          <AppHeader
            pageHeading={appLabels["account"]["add-money"]}
            isHomeLink={true}
            isBackButton={true}
            /*backButtonDestination={
              window.location.href.includes("paymentMethods")
                ? "/account/payment-methods"
                : "/pay-in-store"
            }*/
          />
        ) : (
          <AppDesktopHeader />
        )}

        <div className={`addMoney__from-wrapper${deviceWidth >= 660 ? " desktop-container" : ""}`}>
          {deviceWidth >= 660 && (
            <h1 className="addMoney__title">{appLabels["account"]["add-money"]}</h1>
          )}
          <div className={`form__field-wrapper`}>
            <div className="addMoney__label">{appLabels["account"]["card"]}</div>
            {activeLoyaltyCard && loyaltyCards && (
              <select
                onChange={updateSelectedGiftCard}
                id="gift-card__select"
                name="gift-card__select"
                className="form__field"
                value={activeLoyaltyCard.serial}
              >
                {loyaltyCards.map((option) => (
                  <option key={option.serial} value={option.serial}>
                    {option.maskedNumber}
                  </option>
                ))}
              </select>
            )}
            <IconArrowDown />
          </div>

          <div className={`form__field-wrapper`}>
            <div className="addMoney__label">{appLabels["account"]["method-of-payment"]}</div>
            {hasStoredCreditCard && storedMaskedCCInfo ? (
              <div className="addMoney__stored-cc-details-container">
                <div className="addMoney__stored-cc-container">
                  <div className="addMoney__stored-cc">
                    <IconCheck />
                    <span>{storedMaskedCCInfo.maskedNumber}</span>
                  </div>
                  <div className="addMoney__stored-cc-expiry">
                    {appLabels["general"]["expires-on"] + ": " + storedMaskedCCInfo.expiry}
                  </div>
                </div>
                <Form
                  isFAB={true}
                  isFABFormValid={areOptionsValid}
                  submitForm={handleReload}
                  submitButtonText={
                    autoReload && isAutoReloadEnabled ? (
                      alreadyHasAutoReload ? (
                        appLabels["account"]["update-auto-reload"]
                      ) : (
                        appLabels["account"]["set-auto-reload"]
                      )
                    ) : (
                      <>
                        {appLabels["account"]["add-money-submit-button"]}{" "}
                        {isLeftCurrencySymbol
                          ? currencySymbol + displayAmount
                          : displayAmount + currencySymbol}
                      </>
                    )
                  }
                  submitButtonSuccessText={appLabels["account"]["added"]}
                  submitAPIError={reloadErrorMessage}
                  isDisableSubmit={false}
                  buttonWrapperClass={"FAB-wrapper__addMoney"}
                  customStyle={{ padding: "1em", maxWidth: "250px", width: "70%" }}
                >
                  <FormInput
                    isMaskedInput={false}
                    type="tel"
                    label={appLabels["form"]["cvv"]}
                    id="input--credit-card-cvv"
                    name="cc-cvv"
                    isRequired={true}
                    customerWrapperClass={"addMoney__cvv-input-wrapper"}
                    autocomplete={"on"}
                    maxlength={4}
                    customLabelClass={"addMoney__cvv-input-label"}
                  />
                </Form>
              </div>
            ) : (
              <button
                type="button"
                className="addMoney__add-new-cc"
                onClick={() => setShowCreditCardForm(true)}
              >
                <IconPlusCircle aria-hidden="true" />
                <span>{appLabels["order"]["add-money-add-credit-card"]}</span>
              </button>
            )}
          </div>
          {hasStoredCreditCard && (
            <>
              <div
                className={`addMoney__reload-amount-container ${
                  !autoReload || !isAutoReloadEnabled ? "fadeInContainer" : "fadeOutContainer"
                } ${isManualReloadEnabled ? "" : " addMoney__manual-reload-disbaled"}`}
              >
                <div className="addMoney__label">{appLabels["account"]["reload-amount"]}</div>
                <div className="input-container">
                  <span className="money-icon">{currencySymbol}</span>
                  <input
                    className="addMoney__input-field"
                    type="number"
                    name="Reload Amount"
                    value={reloadAmount}
                    onChange={(e) => {
                      updateReloadAmount(e.target.value);
                    }}
                  ></input>
                </div>
              </div>
              {isAutoReloadEnabled && isGiftCardAutoReloadServiceEnabled && (
                <div
                  className={`addMoney__auto-reload ${
                    autoReload && isManualReloadEnabled ? "slideUpContainer" : ""
                  }`}
                >
                  <div
                    className={`addMoney__auto-reload-container ${
                      isManualReloadEnabled ? "" : " visually-hidden"
                    }`}
                  >
                    <span>{appLabels["account"]["auto-reload"]}</span>
                    <label className={`switch`}>
                      <input
                        type="checkbox"
                        onChange={AutoReloadToggleOnChange}
                        checked={autoReload}
                      />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  {autoReload && (
                    <>
                      <div className="addMoney__auto-reload-elements">
                        <div className="hid-box">
                          <div className="addMoney__label">
                            {appLabels["account"]["auto-reload-amount"]}
                          </div>
                          <div className="input-container">
                            <span className="money-icon">{currencySymbol}</span>
                            <input
                              className="addMoney__input-field"
                              type="number"
                              name="When Balance Reaches"
                              value={autoReloadAmount}
                              onChange={(e) => {
                                udpdateAutoReloadAmount(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div className="addMoney__label">
                            {appLabels["account"]["when-balance-reaches"]}
                          </div>
                          <div className="input-container">
                            <span className="money-icon">{currencySymbol}</span>
                            <input
                              className="addMoney__input-field"
                              type="number"
                              name="When Balance Reaches"
                              value={reloadThreshold}
                              onChange={(e) => {
                                updateReloadThreshold(e.target.value);
                              }}
                            ></input>
                          </div>
                          <div className="addMoney__label">
                            {appLabels["account"]["choose-how-often-to-reload"]}
                          </div>
                          <div className={`form__field-wrapper`}>
                            <select
                              onChange={(e) => updateReloadFrequencyType(e.target.value)}
                              id={"reload-frequency__select"}
                              name={"reload-frequency__select"}
                              className="form__field"
                              value={reloadFrequecyType}
                            >
                              {paymentFrequencyOptions.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            <IconArrowDown />
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {showCreditCardForm && (
        <div className="add-money__credit-card-form">
          <AddCreditCard
            goBackToPayInStore={true}
            setStoredMaskedCCInfo={setStoredMaskedCCInfo}
            setShowCreditCardForm={setShowCreditCardForm}
          />
        </div>
      )}
      {showAutoReloadMessage && (
        <DialogModal message={autoReloadMessage} resetRemoveDialog={acknowledgeAutoReloadMessage} />
      )}
      {is3dSecureRejected && (
        <DialogModal
          message={appLabels["form"]["generic-fallback-api-error"]}
          resetRemoveDialog={() => {
            window.location.hash = "#/pay-in-store/add-money";
            setIs3dSecureRejected(null);
          }}
        />
      )}
    </>
  );
};
